import React from 'react';
import './index.css'
import { EnvironmentVariables } from './EnvironmentVariables';
import { ListItem } from './components/ListItem';

interface Application {
    applicationName: string,
    applicationUrl: string,
    active: boolean
}

function App() {
    const [userInfo, setUserInfo] = React.useState<object | null>(null);
    const [applications, setApplications] = React.useState<Application[]>([]);

    const getUserInfoAndApplications: () => Promise<void> = async () => {
        console.log('Getting user info')
        const userInfoResponse = await fetch(`${EnvironmentVariables.ApiUrl}/user-info`, {
            method: 'GET',
            headers: {
                'Content-type' : 'application/json'
            },
            credentials: 'include'
        })
        console.log(`USER INFO RESPONSE: ${JSON.stringify(userInfoResponse)}`)
        if (!userInfoResponse.ok) {
            console.log('HANDLING FAIL')
            if ([401, 403].includes(userInfoResponse.status)) {
                window.location.href = EnvironmentVariables.AuthUrl
            }
            return
        }
        const userInfoJson = await userInfoResponse.json()
        console.log(`USER INFO JSON: ${JSON.stringify(userInfoJson)}`)

        // get applications
        const userApplicationsResponse = await fetch(
            `${EnvironmentVariables.ApiUrl}/get-applications/${userInfoJson.user}`,
            {
                method: 'GET',
                headers: {
                    'Content-type' : 'application/json'
                },
                credentials: 'include'
            }
        )
        if (!userApplicationsResponse.ok) {
            console.log('HANDLING GET APPLICATIONS FAIL')
            setApplications([])
            return
        }

        const userApplicationsJson: Application[] = (await userApplicationsResponse.json()).filter(
            (application: Application) => application.applicationName !== "Unified Portal Launchpad"
        )

        if (userApplicationsJson.length === 1) {
            // If there is only 1 application, redirect to it
            window.location.href = userApplicationsJson[0].applicationUrl
            return
        }

        setApplications(userApplicationsJson)
        setUserInfo(userInfoJson)
    }

    React.useEffect(() => {
        getUserInfoAndApplications()
    }, [])

    return (
        <div>
            <div className="flex flex-col w-full text-xl tracking-normal leading-5 whitespace-nowrap bg-stone-100 border-slate-800 text-slate-950 max-md:max-w-full">
                <header className="flex gap-5 px-4 py-1.5 bg-stone-100 border-slate-800 max-md:flex-wrap">
                    <img
                        src="/siemensLogoWhiteBanner.png"
                        alt="Logo"
                        className='h-7 mt-7 col-start-1 col-end-4'
                    />
                    <h1 className="flex-1 text-ellipsis max-md:max-w-full" style={{alignSelf: "end"}}>Unified Portal Launchpad</h1>
                </header>
            </div>
            <div className='border-b-[1px] border-secondary-deep-blue-80 pb-4'>
            </div>
            <div className="flex flex-col w-full h-full pt-6 items-center">
                <div className='w-1/2'>
                    {(userInfo && applications.length > 0) && applications.map((application: Application) => (
                        <ListItem
                            key={application.applicationName}
                            title={application.applicationName}
                            subTitle={application.applicationUrl}
                            active={application.active}
                            onClick={() => {window.open(application.applicationUrl, '_blank')}} />
                    ))}
                    {userInfo && applications.length == 0 && <p className='text-center'>No applications available.</p>}
                </div>
            </div>
        </div>
    )
}

export default App
