interface listItemProps {
    title: string,
    subTitle: string,
    active: boolean,
    onClick: () => void
}

export const ListItem = ({title, subTitle, active, onClick}: listItemProps) => {
    const activeStyle = 'flex flex-row justify-between hover:bg-elevation-2 mb-2 rounded-sm bg-elevation-1 h-16 border-solid border-l-[5px] border-solid-bold-green cursor-pointer'
    const inActiveStyle = 'flex flex-row justify-between mb-2 rounded-sm bg-elevation-1 h-16 border-solid border-l-[5px] border-feedback-red'
    return (
        <div className={active ? activeStyle : inActiveStyle} onClick={active ? onClick : undefined}>
            <div className=" pl-4 mt-2">
                <div>
                    <span className="font-bold jbp-list-item-title">{title}</span>
                </div>
                <div>
                    <span className="text-sm text-soft-elevation-0 jbp-list-item-subtitle">{subTitle == undefined ? "" : subTitle}</span>
                </div>
            </div>
            <div className="mt-6 mr-6">
                <img src={active ? "/externalLinkButtonActive.svg" : "/externalLinkButtonDisabled.svg"} alt="External Link" className="h-5" />
            </div>
        </div>
    )
}
